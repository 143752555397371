import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { getDiscoveryHostname } from './utils';

type FirebaseApp = firebase.app.App | null;
const useFirebase = (): FirebaseApp => {
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp>(null);
  useEffect(() => {
    const initializeApp = async () => {
      if (!firebase.apps.length) {
        const discoveryHostname = getDiscoveryHostname();
        const discoveryURL = `https://${discoveryHostname}/firebaseConfig`;

        const req = await fetch(discoveryURL);
        const config = await req.json();
        const app = firebase.initializeApp(config);
        await app.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        setFirebaseApp(app);
        return;
      }
      setFirebaseApp(firebase.app());
    };
    initializeApp();
  }, []);

  return firebaseApp;
};

export default useFirebase;
