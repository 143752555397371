import firebase from 'firebase/app';
import 'firebase/auth';
import { ENV_DOMAIN } from './consts';

export interface ITenantProviderInfo {
  name: string;
  displayName?: string;
}

export interface ITenantInfo {
  providers: ITenantProviderInfo[];
  tenantId: string;
}

export const getDiscoveryHostname = (): string => {
  const hostname = window.location.hostname;
  if (!hostname.startsWith('login.')) {
    return `functions.brainos.${ENV_DOMAIN}/discovery`;
  }
  return `functions.${hostname.replace('login.', '')}/discovery`;
};

// eslint-disable-next-line no-control-regex
const emailRegex =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

export const isEmailValid = (email: string): boolean => {
  // regex comes from https://emailregex.com/
  return emailRegex.test(email);
};

export const lookupTenant = async (
  domain: string,
): Promise<ITenantInfo | null> => {
  if (!domain) {
    return null;
  }
  const discoveryHostname = getDiscoveryHostname();
  const discoveryURL = `https://${discoveryHostname}/tenant?domain=${domain.toLowerCase()}`;

  const req = await fetch(discoveryURL);
  if (req.status !== 200) {
    return null;
  }
  const config = (await req.json()) as ITenantInfo;
  return config;
};

const getRedirectHostnameMatchers = (): RegExp[] => {
  const hostname = window.location.hostname;
  const nonprodRegex = [
    /^iap\.googleapis\.com$/,
    /^localhost$/,
    /\.brainos\.dev$/,
    /\.brainosscale\.dev/,
    /\.brainostest\.dev/,
    /^bc-nonprod-roc-1a1c--pr.*\.web\.app$/,
    /^192\.168\..*/,
  ];
  const prodRegex = [
    /\.brainos\.com$/,
    /^iap\.googleapis\.com$/,
    /^localhost$/,
    /^192\.168\..*/,
  ];
  return hostname.includes('.dev') || hostname.includes('localhost')
    ? nonprodRegex
    : prodRegex;
};

export const isValidRedirectURI = (redirect_uri: string | null): boolean => {
  if (!redirect_uri) {
    return false;
  }
  try {
    const hostname = new URL(redirect_uri).hostname;
    for (const m of getRedirectHostnameMatchers()) {
      if (hostname.match(m)) {
        return true;
      }
    }

    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const storeRedirectInfo = (): void => {
  const url = new URLSearchParams(window.location.search);
  const redirect_uri = url.get('redirect_uri');
  const state = url.get('state');
  const loginType = url.get('login_type');
  if (!isValidRedirectURI(redirect_uri)) {
    return;
  }
  window.sessionStorage.setItem('redirect_uri', redirect_uri as string);
  if (state) {
    window.sessionStorage.setItem('state', state as string);
  }
  if (loginType) {
    window.sessionStorage.setItem('login_type', loginType as string);
  }
};

export const getUserFromUrl = (): {
  email: string;
  tenantDomain: string;
  provider: string;
} => {
  const url = new URLSearchParams(window.location.search);
  return {
    tenantDomain: url.get('tenant') || '',
    email: url.get('email') || '',
    provider: url.get('provider') || '',
  };
};

export const handleUserLoggedIn = async (
  user: firebase.User | null,
): Promise<void> => {
  const redirect_uri = window.sessionStorage.getItem('redirect_uri');
  const login_type = window.sessionStorage.getItem('login_type');

  if (user === null && redirect_uri && window.top) {
    // tell webapps to redirect
    window.top.postMessage('unauthenticated', redirect_uri);
    return;
  }
  if (login_type === 'web' && redirect_uri) {
    window.sessionStorage.removeItem('login_type');
    window.location.href = redirect_uri!;
    return;
  }
  if (redirect_uri && window.top) {
    // send user obj to web apps that have page embedded as iframe
    window.top.postMessage(JSON.stringify(user), redirect_uri);
  }
  if (!redirect_uri) {
    // @ts-ignore ReactNativeWebView is only present in mobile app web view
    window.ReactNativeWebView?.postMessage(JSON.stringify(user));
    return;
  }

  const state = window.sessionStorage.getItem('state');
  if (!state && redirect_uri) {
    const redirectOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    };
    try {
      await fetch(redirect_uri, redirectOptions);
      return;
    } catch (err) {
      console.error(err);
      return;
    }
  }

  const exchangeBody = {} as any;
  exchangeBody.state = state;
  exchangeBody.id_token = await user!.getIdToken();
  const exchangeOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(exchangeBody),
  };
  const req = await fetch(redirect_uri, exchangeOptions);
  const res = await req.json();

  const iapOptions: RequestInit = {
    mode: 'cors',
    credentials: 'include',
    headers: {
      'x-iap-3p-token': res.redirectToken,
    },
  };
  await fetch(res.targetUri, iapOptions);
  document.location = res.originalUri;
};

const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length !== 2) {
    return '';
  }
  return parts.pop()!.split(';').shift();
};

export const storeFirebaseUserCookie = (user: firebase.User | null) => {
  if (navigator.vendor.includes('Apple')) {
    const encodedUser = encodeURIComponent(JSON.stringify(user));
    document.cookie = `firebaseuser=${encodedUser}; Secure;`;
    sessionStorage.setItem('firebaseuser', encodedUser);
  }
};

export const getFirebaseUserCookie = (): any => {
  try {
    const sessionStorageUser = sessionStorage.getItem('firebaseuser');
    if (sessionStorageUser) {
      return JSON.parse(decodeURIComponent(sessionStorageUser));
    }
    const rawCookieVal = getCookie('firebaseuser');
    if (!rawCookieVal) {
      return null;
    }
    return JSON.parse(decodeURIComponent(rawCookieVal));
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const expireFirebaseUserCookie = () => {
  sessionStorage.removeItem('firebaseuser');
  document.cookie = `firebaseuser=;expires=${new Date(0).toUTCString()}`;
};
