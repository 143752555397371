import { FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
// components
import Login from './screens/login/login';
import Reset from './screens/reset/reset';

const AppRoutes: FC = () => {
  // TODO: wrap Reset and Login in common layout wrapper to deduplicate styles/code
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/reset" element={<Reset />} />
      {/*make any route other than /reset redirect to root login page*/}
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
