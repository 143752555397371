import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFirebase from '../../use-firebase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { ErrorOutline, Visibility, VisibilityOff } from '@material-ui/icons';
import PasswordValidator from 'password-validator';
import { ENV_DOMAIN } from '../../consts';

type ResetErrorType =
  | 'auth/expired-action-code'
  | 'auth/invalid-action-code'
  | 'auth/user-disabled'
  | 'auth/user-not-found';

interface IState {
  loading: boolean;
  resetError: {
    message: string;
    type: ResetErrorType | '';
  };
  inputError: {
    message: string;
    type: string;
  };
  isButtonLoading: boolean;
  email: string;
  newPassword: string;
  isPasswordVisible: boolean;
  resetCode: string;
  isValidResetCode: boolean;
  tenantId: string;
  success: boolean;
  showForgotPasswordScreen: boolean;
}

const Reset = () => {
  const classes = useStyles();
  const firebaseApp = useFirebase();
  const navigate = useNavigate();
  const [state, setState] = useState<IState>({
    loading: true,
    resetError: {
      message: '',
      type: '',
    },
    inputError: {
      message: '',
      type: '',
    },
    isButtonLoading: false,
    email: '',
    newPassword: '',
    isPasswordVisible: false,
    resetCode: '',
    isValidResetCode: false,
    tenantId: '',
    success: false,
    showForgotPasswordScreen: false,
  });

  useEffect(() => {
    const initializePasswordReset = async () => {
      if (firebaseApp) {
        const auth = firebaseApp.auth();
        const url = new URLSearchParams(window.location.search);
        const resetCode = url.get('oobCode') || '';
        const tenantId = url.get('tenantId') || '';
        const urlEmail = url.get('email') || '';
        if (tenantId) {
          auth.tenantId = tenantId;
        }
        if (urlEmail) {
          // this means user requested new reset email
          try {
            await auth.sendPasswordResetEmail(urlEmail);
            setState(prev => ({
              ...prev,
              showForgotPasswordScreen: true,
              email: urlEmail,
            }));
          } catch (e: any) {
            setState(prev => ({
              ...prev,
              showForgotPasswordScreen: true,
              email: urlEmail,
            }));
            if (e.code === 'auth/user-not-found') {
              return;
            }
            console.error(e);
          }
          return;
        }
        if (resetCode) {
          auth
            .verifyPasswordResetCode(resetCode)
            .then(email => {
              setState(prev => ({
                ...prev,
                resetCode,
                email,
                tenantId,
                isValidResetCode: true,
                loading: false,
              }));
            })
            .catch(err => {
              console.error(err);
              const errorCode = err.code as ResetErrorType;
              let errorMessage =
                'Something went wrong. Please click the button below, enter your email, and click "Forgot Password" to receive a new reset email.';
              if (errorCode === 'auth/expired-action-code') {
                errorMessage =
                  'Your reset request has expired. Please click the button below, enter your email, and click "Forgot Password" to receive a new reset email.';
              }
              setState(prev => ({
                ...prev,
                isValidResetCode: false,
                loading: false,
                resetError: {
                  message: errorMessage,
                  type: errorCode,
                },
              }));
            });
          return;
        }
        navigate('../'); // if no resetCode present, navigate to login screen
      }
    };
    initializePasswordReset();
  }, [firebaseApp, navigate]);

  const handlePasswordUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setState(prev => ({
      ...prev,
      newPassword: value,
      inputError: { message: '', type: '' },
    }));
  };

  const handlePasswordReset = async (event: FormEvent) => {
    event.preventDefault();
    setState(prev => ({ ...prev, isButtonLoading: true }));
    const auth = firebaseApp!.auth();
    if (state.tenantId) {
      auth.tenantId = state.tenantId;
    }
    const passwordSchema = new PasswordValidator();
    const [emailName, domain] = state.email.split('@');
    const passwordBlackList = [emailName, domain.split('.')[0]];
    const regex = new RegExp(`${passwordBlackList.join('|')}`, 'gi');
    passwordSchema
      .is()
      .min(10, 'Password must be at least 10 characters.')
      .has()
      .uppercase(1, 'Password must have at least one uppercase letter.')
      .has()
      .lowercase(1, 'Password must have at least one lowercase letter.')
      .has()
      .symbols(1, 'Password must have at least one special character.')
      .has()
      .digits(1, 'Password must have at least one number.')
      .not(regex, 'Password must not contain your username.');
    if (passwordSchema.validate(state.newPassword)) {
      try {
        await auth.confirmPasswordReset(state.resetCode, state.newPassword);
        setState(prev => ({
          ...prev,
          isButtonLoading: false,
          inputError: {
            message: '',
            type: '',
          },
          loading: true,
          success: true,
        }));
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, '', `${document.location.origin}/reset`);
        // removes search params so that user is redirected to login on refresh instead of error
      } catch (err: any) {
        console.error(err);
        let errorMessage = err.message || 'Something went wrong. Try again.';
        if (err.code === 'auth/weak-password') {
          errorMessage = 'Your password is too weak.';
        }
        setState(prev => ({
          ...prev,
          isButtonLoading: false,
          inputError: {
            message: errorMessage,
            type: err.code,
          },
        }));
      }
    } else {
      // password validation failed, tell user why
      const validationMessages = passwordSchema.validate(state.newPassword, {
        details: true,
      }) as { validation: string; message: string }[];
      setState(prev => ({
        ...prev,
        isButtonLoading: false,
        inputError: {
          message: validationMessages[0].message,
          type: validationMessages[0].validation,
        },
      }));
    }
  };

  const RoundedButton = withStyles(() => ({
    root: {
      backgroundColor: '#F47521',
      color: '#FFFFFF',
      textTransform: 'none',
      width: 335,
      borderRadius: 21.5,
      '&:disabled': {
        color: '#FFFFFF',
      },
      '&:hover': {
        backgroundColor: 'rgba(255,119,33,0.76)',
      },
    },
  }))(Button);

  const renderCodeValidationError = () => {
    const { message, type } = state.resetError;
    if (type) {
      return (
        <>
          <br />
          <span className={classes.error}>{message}</span>
          <RoundedButton
            variant="contained"
            name="backToSignIn"
            className={classes.button}
            onClick={() => navigate('../')}
          >
            Back to Sign In
          </RoundedButton>
        </>
      );
    }
    return null;
  };

  const renderResetForm = () => {
    return (
      <>
        <form onSubmit={handlePasswordReset} className={classes.passwordForm}>
          <TextField
            variant="outlined"
            className={classes.passwordTextField}
            id="password"
            label="New Password"
            name="password"
            aria-label="new password"
            autoComplete="new-password"
            type={state.isPasswordVisible ? 'text' : 'password'}
            onChange={handlePasswordUpdate}
            value={state.newPassword}
            error={!!state.inputError.message}
            autoFocus
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setState(prev => ({
                        ...prev,
                        isPasswordVisible: !prev.isPasswordVisible,
                      }));
                    }}
                    edge="end"
                  >
                    {state.isPasswordVisible ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={
              state.inputError.type && (
                <span className={classes.helperMessage}>
                  <ErrorOutline className={classes.errorIcon} />{' '}
                  {state.inputError.message}
                </span>
              )
            }
          />
          <br />
          <RoundedButton
            variant="contained"
            name="resetPassword"
            disabled={
              state.isButtonLoading ||
              !!state.inputError.type ||
              !state.newPassword
            }
            className={classes.stickyButton}
            type="submit"
          >
            {state.isButtonLoading ? '' : 'Reset Password'}
          </RoundedButton>

          {state.isButtonLoading && (
            <CircularProgress size={24} className={classes.buttonLoader} />
          )}
        </form>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            marginTop: '1rem',
          }}
        >
          <Typography
            variant="body2"
            color={'textSecondary'}
            style={{
              fontSize: 11,
              width: 300,
            }}
          >
            {`Password Requirements*: 10 characters, one uppercase letter, one special character, a number, and cannot contain your username`}
          </Typography>
        </Box>
      </>
    );
  };

  const getSuccessText = (): string => {
    return state.success
      ? // upon success, show success message
        'You may now sign in with your new password.'
      : '';
  };
  const resetForm = state.loading ? (
    <>
      <div className={classes.closePage}>{getSuccessText()}</div>
      {state.success ? (
        // upon success, show a button to redirect user back to login page with subsequent redirect to BrainOS Hub
        <RoundedButton
          variant="contained"
          className={classes.button}
          href={`https://www.brainos.${ENV_DOMAIN}/login`}
        >
          Sign in with new password
        </RoundedButton>
      ) : (
        <CircularProgress />
      )}
    </>
  ) : (
    renderResetForm()
  );

  return (
    <div className={classes.container}>
      {state.showForgotPasswordScreen ? (
        <div className={classes.app}>
          <img
            src="/emailIcon.svg"
            className={classes.emailIcon}
            alt="email icon"
          />
          <h1 className={classes.title}>Please Check Your Email</h1>
          <div className={classes.forgotBlurb}>
            You will receive a new email at{' '}
            <span className={classes.bold}>{state.email}</span> with
            instructions on how to reset your password. Please be sure to check
            your spam folder. If you do not receive an email please contact your
            administrator.
            <br />
            <br />
            You may now close this page.
          </div>
        </div>
      ) : (
        <div className={classes.app}>
          <img
            src="/brainLogo.svg"
            className={classes.brainLogo}
            alt="Brain Corp"
          />
          <h1 className={classes.title}>
            {state.success ? 'Password Changed ' : 'Reset Password'}
          </h1>
          {state.email && <span>{state.email}</span>}
          {renderCodeValidationError()}
          {state.isValidResetCode && resetForm}
        </div>
      )}

      <div className={classes.footer}>
        <a
          href="https://www.braincorp.com/data-privacy/"
          target="_blank"
          rel="noreferrer"
          className={classes.privacy}
        >
          Privacy
        </a>
        <span className={classes.trademark}>
          © {new Date().getFullYear()} Brain Corporation. All rights reserved.
        </span>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  app: {
    [theme.breakpoints.up('lg')]: {
      margin: '20vh auto 0 auto',
      height: 443,
      maxWidth: 612,
      boxShadow: '0 24px 37px 1px rgba(0,0,0,0.1)',
    },
    [theme.breakpoints.down('md')]: {
      margin: '-50px 0 0 -7px',
      height: '108vh',
      width: '100vw',
    },
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
  },
  brainLogo: {
    paddingTop: 70,
    height: 43,
    width: 107,
  },
  title: {
    color: '#212121',
    fontWeight: 'bold',
    fontSize: 22,
  },
  passwordForm: {
    position: 'relative',
    height: 159,
    width: 335,
    margin: '0 auto',
    marginTop: 6,
  },
  passwordTextField: {
    marginTop: 15,
    width: 335,
  },
  stickyButton: {
    marginTop: 30,
    height: 45,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  buttonLoader: {
    position: 'absolute',
    top: '81%',
    left: '47%',
    color: '#FFFFFF',
  },
  helperMessage: {
    marginLeft: -15,
    marginTop: -6,
  },
  errorIcon: {
    position: 'relative',
    top: 7,
  },
  error: {
    color: 'red',
    lineHeight: 1.5,
  },
  button: {
    marginTop: 50,
    height: 45,
  },
  closePage: {
    marginTop: 100,
    textAlign: 'center',
  },
  emailIcon: {
    paddingTop: 70,
    height: 88,
    width: 88,
  },
  bold: {
    fontWeight: 'bold',
    display: 'inline',
  },
  forgotBlurb: {
    lineHeight: '26px',
    paddingLeft: 20,
    paddingRight: 20,
  },
  footer: {
    marginTop: 24,
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      display: 'none', // clicking on new link in webview is bad, privacy policy can be found within app
    },
  },
  privacy: {
    fontWeight: 500,
    color: '#0083AF',
    lineHeight: '16px',
    textDecoration: 'none',
  },
  trademark: {
    marginTop: 15,
    display: 'block',
  },
}));

export default Reset;
